<template>
  <v-container class="mt-12 pt-8 serious" style="background:#fafafa;">
    <v-row style="background:#fafafa;">
      <v-col cols="12" class="pa-0">
        <v-row class="py-1 px-5">
          <v-col cols="12" md="4" ACCOUNTTOPINFOandSELECTOR>
            <v-row>
              <v-col cols="12" ACCOUNTTOPINFO>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="4" class="text-center">
                        <div class="mb-2">
                          <v-avatar
                            size="30"
                            v-if="user && user.name"
                            color="primary white--text"
                            style="font-size:12px;"
                          >
                            {{user.name.slice(0,1)}}
                          </v-avatar>
                        </div>
                        <div>{{user.name}}</div>
                      </v-col>
                      <v-col cols="8" class="text-left">
                        <v-chip color="primary" :class="`${arabic?'arFont':'enFont'} white--text`">حساب جديد</v-chip>
                      </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <v-row>
                      <v-col cols="6" :class="`${arabic?'arFont':'enFont'}`">
                        كود الاحالة
                      </v-col>
                      <v-col cols="6" v-if="user.account && user.account.affiliate_code">
                        {{user.account.affiliate_code}}
                      </v-col>
                      <v-col cols="12">
                        <v-btn color="secondary" disabled block small :class="`${arabic?'arFont':'enFont'}`">نسخ رابط الاحالة</v-btn>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" ACCOUNTSELECTOR>
                <v-card>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="text-center">
                        <v-list>
                          <v-list-item-group v-model="selectedPanel">
                            <v-list-item
                              v-for="(item, i) in subitems"
                              :key="i"
                              :value="item.value"
                            >
                              <v-list-item-icon>
                                <v-icon v-text="item.icon"></v-icon>
                              </v-list-item-icon>
                              <v-list-item-content @click="showPannel(item.value)" :class="`${arabic?'arFont':'enFont'}`">
                                <v-list-item-title v-text="item.text"></v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-list-item-group>
                        </v-list>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12" PANNELS>
                <v-card v-if="firstPanel" firstPanel :loading="loading">
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="2" class="text-center" TITLE>
                            <v-icon size="40" color="primary">person</v-icon>
                          </v-col>
                          <v-col cols="10" TITLE>
                            <div :class="`${arabic?'arFont':'enFont'} black--text`"> المعلومات الشخصية</div>
                            <div :class="`${arabic?'arFont':'enFont'} grey--text`"> يمكنك تعديل معلوماتك الشخصية و بعض الإعدادات</div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="6" FIELD>
                            <v-text-field
                              label="الاسم"
                              v-model="user.name"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" FIELD>
                            <v-text-field
                              label="الايميل"
                              v-model="user.email"
                              :class="`${arabic?'arFont':'enFont'}`"
                              disabled
                              readonly
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" FIELD>
                            <v-text-field
                              label="رقم الهاتف"
                              v-model="user.phone"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :class="`${arabic?'arFont':'enFont'}`" @click="UpdateMe(user)">تعديل المعلومات</v-btn>
                  </v-card-actions>
                </v-card>
                <v-card v-if="secondPanel" secondPanel :loading="loading">
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="2" class="text-center" TITLE>
                            <v-icon size="40" color="primary">password</v-icon>
                          </v-col>
                          <v-col cols="10" TITLE>
                            <div :class="`${arabic?'arFont':'enFont'} black--text`">تغيير كلمة المرور</div>
                            <div :class="`${arabic?'arFont':'enFont'} grey--text`">يمكنك تغيير كلمة المرور من هنا</div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="6" FIELD>
                            <v-text-field
                              label="الايميل"
                              v-model="user.email"
                              :class="`${arabic?'arFont':'enFont'}`"
                              disabled
                              readonly
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :class="`${arabic?'arFont':'enFont'}`" @click="resetPass()">طلب تبديل كلمة السر عبر الايميل</v-btn>
                  </v-card-actions>
                </v-card>
                <v-card v-if="thirdPanel" thirdPanel :loading="loading">
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="2" class="text-center" TITLE>
                            <v-icon size="40" color="primary">loyalty</v-icon>
                          </v-col>
                          <v-col cols="10" TITLE>
                            <div :class="`${arabic?'arFont':'enFont'} black--text`">الحسابات المشار إليها</div>
                            <div :class="`${arabic?'arFont':'enFont'} grey--text`">الاحالات الخاصة بك</div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="6" FIELD>
                            <v-card color="grey lighten-2" class="text-center py-12" flat>
                              <div :class="`${arabic?'arFont':'enFont'} black--text`">عدد الحسابات</div>
                              <div style="font-size:40px;" class="mt-5">0</div>
                            </v-card>
                          </v-col>
                          <v-col cols="6" FIELD>
                            <v-card color="grey lighten-2" class="text-center py-12" flat>
                              <div :class="`${arabic?'arFont':'enFont'} black--text`">عدد الطلبات</div>
                              <div style="font-size:40px;" class="mt-5">0</div>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card v-if="fourthPanel" fourthPanel :loading="loading">
                  <v-card-text class="py-0">
                    <v-row>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="2" class="text-center" TITLE>
                            <v-icon size="40" color="primary">store</v-icon>
                          </v-col>
                          <v-col cols="10" TITLE>
                            <div :class="`${arabic?'arFont':'enFont'} black--text`">الحساب التجاري</div>
                            <div :class="`${arabic?'arFont':'enFont'} grey--text`">معلومات واعدادات الحساب التجاري الخاص بك</div>
                          </v-col>
                        </v-row>
                      </v-col>
                      <v-col cols="12">
                        <v-row class="align-center">
                          <v-col cols="12" style="direction:ltr !important;" class="text-left elevation-2" FIELD>
                            <div :class="`${arabic?'arFont':'enFont'} primary--text`">ACCOUNT API TOKEN</div>
                            <v-chip color="primary" :class="`${arabic?'arFont':'enFont'}`">{{user.account.token}}</v-chip>
                            <v-btn 
                              v-clipboard:copy="user.account.token"
                              v-clipboard:success="clipboardSuccessHandler"
                              v-clipboard:error="clipboardErrorHandler"
                              color="success" 
                              x-small 
                              icon>
                              <v-icon size="10">content_copy</v-icon>
                            </v-btn>
                          </v-col>
                          <v-col cols="6" FIELD>
                            <v-text-field
                              label="الاسم"
                              v-model="user.account.name"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" FIELD>
                            <v-text-field
                              label="الايميل"
                              v-model="user.account.email"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" FIELD>
                            <v-switch 
                              :class="`${arabic?'arFont':'enFont'}`" 
                              label="التنبيهات عبر الايميل" 
                              v-model="user.account.notification_emails"
                              inset
                            ></v-switch>
                          </v-col>
                          <v-col cols="6" FIELD>
                            <v-autocomplete
                              :items="paymentMethods"
                              v-model="user.account.payment_method"
                              label="طريقة استلام الارباح"
                              autocomplete="disabled"
                              outlined
                              hide-details
                              clearable
                              dense
                              item-value="slug"
                              :class="`${arabic?'arFont':'enFont'}`"
                            >
                              <template v-slot:selection="{ attr, on, item, selected }">
                                <span
                                  v-bind="attr"
                                  :input-value="selected"
                                  v-on="on"
                                  :class="`${arabic?'arFont':'enFont'}`"
                                >
                                  <v-chip x-small>{{item.text}}</v-chip>
                                </span>
                              </template>
                              <template v-slot:item="{ item }">
                                <span
                                  :class="`${arabic?'arFont':'enFont'}`"
                                >
                                  {{item.text}}
                                </span>
                              </template>
                            </v-autocomplete>
                          </v-col>
                          <v-col cols="6" v-if="user.account && user.account.payment_method == 'zain_cash'" FIELD>
                            <v-text-field
                              label="رقم هاتف للحساب"
                              v-model="user.account.phone_number"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="user.account && user.account.payment_method == 'paypal'" FIELD>
                            <v-text-field
                              label="ايميل حساب البايبال"
                              v-model="user.account.paypal_email"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="user.account && user.account.payment_method == 'bank'" FIELD>
                            <v-text-field
                              label="اسم البنك"
                              v-model="user.account.bank"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="user.account && user.account.payment_method == 'bank'" FIELD>
                            <v-text-field
                              label="اسم صاحب حساب البنك"
                              v-model="user.account.bank_account_name"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="user.account && user.account.payment_method == 'bank'" FIELD>
                            <v-text-field
                              label="رقم حساب البنك IBAN"
                              v-model="user.account.iban"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="6" v-if="user.account && user.account.payment_method == 'bank'" FIELD>
                            <v-text-field
                              label="كود الSWIFT"
                              v-model="user.account.swift"
                              :class="`${arabic?'arFont':'enFont'}`"
                              outlined
                              hide-details
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" :class="`${arabic?'arFont':'enFont'}`" @click="UpdateAccount(user.account)">تعديل المعلومات</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
const headers = {'Accept': 'application/json'};
const fireapi = 'https://us-central1-shark-sellers.cloudfunctions.net/main';

export default {
  data() {
    return {
      loading:false,
      dialog:false,
      vresource:[],
      resource:[],
      search:null,
      rules: {
        required: value => !!value || this.i18n['required'],
        email: value => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.i18n['valid_email']
      },
      resourceKeys:[
        
      ],
      resourceSettings:{
        resourceName:null,
        search:false,
        create:false,
        pagination:true,
      },
      newResource:{},
      editing:false,
      deleting:false,
      firstPanel:true,
      secondPanel:false,
      thirdPanel:false,
      fourthPanel:false,
      selectedPanel:'firstPanel',
      subitems:[
        {
          text:'المعلومات الشخصية',
          icon:'person',
          value:'firstPanel'
        },
        {
          text:'تغيير كلمة المرور',
          icon:'password',
          value:'secondPanel'
        },
        {
          text:'الاحالات',
          icon:'loyalty',
          value:'thirdPanel'
        },
        {
          text:'الحساب التجاري',
          icon:'store',
          value:'fourthPanel'
        },
      ],
      account:{},
    }
  },
  methods: {
    clipboardSuccessHandler (){
      this.$emit('snackbarNotification', `Copied`, 'success')
    },
    clipboardErrorHandler () {
      this.$emit('snackbarNotification', `Failed`, 'error')
    },
    async resetPass(){
      this.loading = true
      // // console.log(this.user)
      let r = await axios.post(`${fireapi}/resetPassword`, this.user, {headers}).then(x=>x.data)
      // console.log(r)
      this.loading = false
    },
    async updateResource(r){
      return new Promise(async (res, rej)=>{
        this.loading = true
        // console.log(r)
        await this.$store.dispatch('putResource', {resource:this.$route.path + `/${r.id}`, value:r})
        this.loadResource()
        this.loading = false
      })
    },
    async UpdateMe(r){
      this.loading = true
      // console.log(r)
      await this.$store.dispatch('putResource', {resource:'/users/updateme' + `/${r.id}`, value:r})
      this.$emit('snackbarNotification', `تم التحديث`, 'success')
      this.loading = false
    },
    async UpdateAccount(r){
      this.loading = true
      // console.log(r)
      delete r.leads
      if(r.payment_method == 'paypal') {
        if(!r.paypal_email) {
          this.$emit('snackbarNotification', `ايميل البايبال مطلوب`, 'error')
          this.loading = false
          return
        }
      } else if(r.payment_method == 'zain_cash') {
        if(!r.phone_number) {
          this.$emit('snackbarNotification', `رقم الهاتف مطلوب`, 'error')
          this.loading = false
          return
        }
      } else if(r.payment_method == 'bank') {
        if(!r.bank || !r.bank_account_name || !r.iban || !r.swift) {
          this.$emit('snackbarNotification', `جميع بيانات حساب البنك مطلوبة`, 'error')
          this.loading = false
          return
        }
      }
      await this.$store.dispatch('putResource', {resource:'/accounts' + `/${r.id}`, value:r})
      this.$emit('snackbarNotification', `تم التحديث`, 'success')
      this.loading = false
    },
    showPannel(v){
      this[v] = true
      let all = this.subitems.map(x=>x.value)
      all.filter(x=>x!=v).forEach(x=>this[x] = false)
    },
    setResourceName(){
      this.resourceSettings.resourceName = this.i18n[this.$route.path.replace('/', '')]
    },
    async getCounters(){
      this.loading = true
      this.counters = await this.$store.dispatch('getResource', {resource:`/leads-counter`})
      // console.log(this.counters)
      this.loading = false
    }
  },
  computed:{
    api(){
      return this.$store.state.settings.apiBase
    },
    users(){
      let sa = this.$store.state.user && this.$store.state.user.role == 'superadmin'
      // console.log(sa)
      return this.$store.state.users.filter(x=>{
        if(!sa) {
          return x.superior == this.$store.state.user.level
        } else return x
      })
    },
    site(){
      return this.$store.state.site
    },
    items() {
      return this.$store.state.menu
    },
    superAdmin(){
      return this.$store.state.superAdmin
    },
    isLogin() {
      return this.$store.state.centralLogin
    },
    language(){
      return this.$store.state.language
    },
    globalLoading(){
      return this.$store.state.globalLoading
    },
    user(){
      return this.$store.state.user
    },
    i18n(){
      return this.$store.state.i18n[this.$store.state.language]
    },
    arabic(){
      if(this.$store.state.language == 'ar') return true
    },
    routeTitle(){
      let main = this.$store.state.menu.filter(x=>x.path == this.$route.path)
      let sub = [].concat(...this.$store.state.menu.map(x=>x.subs)).filter(x=>x).filter(x=>x.path == this.$route.path)
      if(main.length) {
        return this.$store.state.i18n[this.$store.state.language][main[0].text]
      } else if(sub.length) {
        return this.$store.state.i18n[this.$store.state.language][sub[0].text]
      } else {
        return ''
      }
    },
    roles(){
      return this.$store.state.userRoles
    },
    notifications(){
      return this.$store.state.notifications.sort((a, b)=> b.timestamp - a.timestamp)
    },
    paymentMethods(){
      return this.$store.state.paymentMethods
    },
  },
  async created(){
    this.setResourceName()
  },
}
</script>

<style>

</style>